/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Box, Heading, Link} from "@chakra-ui/react";
import {ExternalLinkIcon} from "@chakra-ui/icons";
import Tour from '/static/images/projects/chancellor-consulting/chancellor-consulting-tour.gif';
import {StackIconsGroup} from "components";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/images/projects/featured/chancellor-consutling.png",
    alt: "showcase image of web ui"
  })), "\n", React.createElement(_components.h2, null, "Summary"), "\n", React.createElement(Heading, {
    as: "div",
    size: "md"
  }, React.createElement(_components.p, null, "Design and develop a professional web experience that showcases the years of experience and clients that Chancellor Consulting has worked with. The site is built with Gatsby.js and content types are handled with Netlify CMS.")), "\n", React.createElement(Link, {
    asExternal: true,
    variant: "linkOne",
    href: props.pageContext.frontmatter.link,
    target: "_blank"
  }, "Chancellor Consulting ", React.createElement(ExternalLinkIcon, {
    mx: "2px"
  })), "\n", React.createElement(_components.h3, null, "Stack"), "\n", React.createElement(StackIconsGroup, {
    iconArr: ["chakraui", "gatsby", "graphql", "netlify", "github", "googleanalytics"]
  }), "\n", React.createElement(_components.h3, null, "Results"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/images/projects/chancellor-consulting/chancellor-consulting-tour.gif",
    alt: "letter shuffle tour"
  })), "\n", React.createElement(_components.p, null, "A blazing-fast Gatsby.js site with a user experience focused on showcasing the depth of experience and professionalism of the team."), "\n", React.createElement(_components.h4, null, "Deliverables"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "website"), "\n", React.createElement(_components.li, null, "user experience design"), "\n", React.createElement(_components.li, null, "web design"), "\n", React.createElement(_components.li, null, "development"), "\n", React.createElement(_components.li, null, "set up and integrated Netlify CMS"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
